
/* GLOBAL SETTINGS  */

body {
    margin: 0;
}

p {
    font-size: 14px;
    line-height: 24px;
    font-family: Arial, Helvetica, sans-serif;
    margin: 0px;
    padding: 0px;
}

html{
    -webkit-text-size-adjust: 100%;
    font-family: Mulish,sans-serif;
    line-height: 1;
    tab-size: 4;
}

input {
    background-color: transparent;
    border-color: transparent;
}

.shadow-container {
    visibility: hidden !important;
}

.hide-scrollbar {
    scrollbar-width: none;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

.hide-scrollbar::-webkit-scrollbar {
    -webkit-appearance: none;
}